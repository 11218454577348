<template>
  <div class="content">
    <div class="top">
      <img :src="leadPageUrl" alt="">
    </div>
    <div class="bottom">
      <div class="bottomBtn">
        <div class="btnclick" @click="toInfoColl">
          {{ cardAmount }}, 立即前往
        </div>
      </div>
      <div class="bottomTo">
        <div @click="toIndex">回到首页</div>
        <div v-if="isRule" @click="toRule">奖励规则</div>
      </div>
    </div>
    <van-popup v-model="isRuleShow">
      <div class="popupMask">
        <img
          class="closeClick"
          src="../../../assets/images/cuo-5.png"
          alt=""
          @click="closeMask"
        >
        <div class="title">奖励规则</div>
        <div class="rulebox">
          <div class="rule">
            {{ settlementRules }}
          </div>
        </div>
        <div class="toBtn">
          <div class="toInfoColl" @click="toInfoColl">立即前往</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { getBankInfo, generatePosterForBank } from '@/services/comeOn.js'
import { Popup, Toast } from 'vant'

Vue.use(Toast).use(Popup)

export default {
  name: 'SingleCardShare',
  data() {
    return {
      isRuleShow: false,
      leadPageUrl: '',
      bankCardId: '',
      bankId: '',
      gateWayId: '',
      typeId: '',
      cardAmount: '',
      settlementRules: '',
      isRule: false
    }
  },
  created() {
    const { bankId, type } = this.$route.query
    this.bankId = bankId
    this.type = type
    this.isRule = this.type !== '1'
    this.getBgc()
    this.getBankInfo()
  },
  mounted() {
  },
  methods: {
    // 跳转首页
    toIndex() {
      this.$router.push({
        path: '/Credit/creditcardapply',
        query: {}
      })
    },
    // 展示规则遮罩
    toRule() {
      this.isRuleShow = true
    },
    // 展示规则遮罩
    closeMask() {
      this.isRuleShow = false
    },
    // 立即前往填写信息page
    toInfoColl() {
      this.$router.push({
        path: 'infoCollection',
        query: {
          bankCardId: this.merCardId,
          bankId: this.bankId,
          gateWayId: this.gatewayId,
          typeId: this.type
        }
      })
    },
    // get银行背景图
    getBgc() {
      generatePosterForBank({
        mid: window.localStorage.getItem('uid'),
        bankId: this.bankId,
        type: this.type
      }).then(res => {
        if (Number(res.code) === 200) {
          this.leadPageUrl = res.data.noQrImg
        } else {
          Toast(res.msg)
        }
      })
    },
    // 获取银行数据
    getBankInfo() {
      const infoData =
        this.bankId +
        '/' +
        window.localStorage.getItem('uid') +
        '/' +
        this.type
      getBankInfo(infoData).then((res) => {
        if (Number(res.code) === 200) {
          document.title = res.data.merCardName
          this.merCardId = res.data.merCardId
          this.bankId = res.data.bankId
          this.gatewayId = res.data.gatewayId
          this.typeId = res.data.typeId
          this.cardAmount = res.data.cardAmount
          this.settlementRules = res.data.settlementRules
        } else if (Number(res.code) === 400) {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100vw;
  height: 100vh;

  .top {
    width: 100vw;
    height: 80vh;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .bottom {
    width: 100vw;
    height: 130px;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 7px;

    .bottomBtn {
      width: 100vw;
      height: 75px;
      overflow: hidden;

      .btnclick {
        width: 308px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        color: #fff;
        font-size: 15px;
        font-weight: 500;
        background: -webkit-linear-gradient(top, #fed4b4, #dda67c);
        border-radius: 20px;
        margin: 20px auto 10px;
      }
    }

    .bottomTo {
      width: 308px;
      height: 45px;
      display: flex;
      overflow: hidden;
      margin: 0 auto;

      div {
        width: 50%;
        height: 45px;
        line-height: 45px;
        margin: 0 auto;
      }
    }
  }

  /deep/ .van-popup {
    background-color: rgb(0, 0, 0) !important;
  }

  .popupMask {
    width: 294px;
    height: 250px;
    background-color: #fff;
    border-radius: 7px;

    .closeClick {
      width: 11px;
      height: 11px;
      position: absolute;
      right: 14px;
      top: 14px;
    }

    .title {
      line-height: 60px;
      text-align: center;
    }

    .rulebox {
      width: 246px;
      height: 86px;
      overflow-x: hidden;
      margin: 0 auto;

      .rule {
        width: 246px;
        height: 86px;
        font-size: 13px;
        font-family: PingFang SC Regular, PingFang SC Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 18px;
      }
    }

    .toBtn {
      margin-top: 39px;

      .toInfoColl {
        width: 235px;
        height: 45px;
        border-radius: 7px;
        text-align: center;
        color: #fff;
        margin: 0 auto;
        background-image: linear-gradient(#fed4b4, #dda67c);
        font-size: 14px;
        line-height: 45px;
      }
    }
  }
}
</style>
